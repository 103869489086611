import React, { FC, useState, useEffect } from "react";
import {
  Form,
  Stack,
  Tab,
  Tabs,
  Row,
  Col,
  Table,
  Card,
  Modal,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "redux/reducers";
import Confirmation, {
  IConfirmationBoxData,
} from "../../components/shared/Confirmation";
import CurrencySymbols from "components/shared/currencySymbols";
import ToolTip from "components/shared/ToolTip";
import { downloadingAdmissionForm, getlogo, printingAdmissionForm, printingReceipt } from "redux/actions/downloads";
import { getStudent, getStudentJourney, updateStudent } from "redux/actions/user";
import { registerLogo } from "redux/actions/logosettings";
import {
  getStudentfees,
  StudentFeetransactions,
} from "redux/actions/studentfee";
import dateFormatter from "utils/dateFormatter";
import { setAlert } from "redux/actions/alert";
import ButtonLoader from "components/shared/Buttons/ButtonLoader";
import SearchSelect from "components/dropdowns/SearchSelect";
import { getStudentsandStaff, searchStudents } from "redux/actions/user";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import RemoveHtmlTags from "utils/removeHtmlTags";
import moment from "moment";
import MultiSelect from "components/dropdowns/multiSelect";
import useApi from "components/hooks/useApi";
import { getEnrolledStudents } from "services/admissions";
import BackButton from "components/shared/Buttons/BackButton";
import { uploadAttachment } from "services/media";
import { deleteLogo } from "redux/actions/logosettings";


interface StudentInfoInitialValues {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  isTransportNeeded: string;
  fatherName: string;
  motherName: string;
  phone: string;
  fatherEmail: string;
  currentAddress: {
    state: string;
  };
  fatherMobile: string;
  motherMobile: string;
  email: string;
  siblings: string | string[];
}


const StudentProfile: React.FC = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let curUser = localStorage.getItem("userId");
  let s3_uniqId = localStorage.getItem("s3_uniqId");

  const branchId = localStorage.getItem("branchId");
  const branchAcademicBatchId = localStorage.getItem("branchAcademicBatchId");

  const selectedStudent = location.state && (location.state as any).selectedStudent;
  const pagination = location.state && (location.state as any).pagination;
  const filterPayload = location.state && (location.state as any).filterPayload;

  const [profileData, setprofileData] = React.useState<any>();
  const [editingMode, setEditingMode] = React.useState<any>(false);
  const [studentFeeHistory, setStudentFeeHistory] = React.useState<any>();
  const [feePlanData, setFeePlanData] = React.useState<any>([]);

  const { loading: logoLoading, logo } = useSelector(
    (state: RootState) => state.logo
  );
  const {
    loading: studentfeeLoading,
    studentfee,
    studentfeetransactions,
  } = useSelector((state: RootState) => state.studentfee);

  const { loading: usersLoading, student, studentJourney, studentJourneyLoading } = useSelector((state: RootState) => state.user);
  const { action: getEnrolledStudentsHelper, data: admittedStudents } = useApi(getEnrolledStudents);

  const defaultStatus = location.state && (location.state as any).status;
  const selectedBranchId = location.state && (location.state as any).selectedBranchId;
  const selectedYear = location.state && (location.state as any).selectedYear;

  const firstUpdate = React.useRef(true);

  const [imgSrc, setImgSrc] = React.useState(
    "/images/profile-img-placeholder.svg"
  );
  const [imgPath, setImgPath] = React.useState("");
  const [phoneError, setPhoneError] = useState<any>('');
  const [fatherPhoneError, setFatherPhoneError] = useState<any>('');
  const [motherPhoneError, setMotherPhoneError] = useState<any>('');
  const [emailError, setEmailError] = useState('');

  const [isPrintLoader, setIsPrintLoader] = React.useState("");
  const [isDownloadLoader, setIsDownloadLoader] = React.useState(false);

  const [confirmationBoxData, setConfirmationBoxData] =
    React.useState<IConfirmationBoxData>({ show: false, data: "" });
  const [confirmationBoxDelete, setConfirmationBoxDelete] =
    React.useState<IConfirmationBoxData>({ show: false, data: "" });

  const [isPrinterAdmissionLoader, setIsPrintAdmissionLoader] = React.useState(false);

  const [selectedValues, setSelectedValues] = useState([]);
  const [options, setOptions] = useState([]);

  // const [options, setOptions] = React.useState([]);
  const [show, setShow] = useState(false);
  // const [search, setSearch] = useState(false);
  // const [searchedStudent, setSearchedStudent] = useState<any>();

  const {
    register: studentInfoRegister,
    handleSubmit: studentInfoHandleSubmit,
    reset: studentInfoReset,
    setValue,
  } = useForm<StudentInfoInitialValues>();

  const setEditingHandler = () => {
    setEditingMode((prev: any) => !prev);
  };

  const changeTabHandler = (key) => {
    if (key === "Fee Details") {
      dispatch(
        StudentFeetransactions({ studentBranchAcademicYearId: selectedStudent })
      );
    }
    // if(key === "Fee Details" && search){
    //   dispatch(StudentFeetransactions({ studentBranchAcademicYearId: searchedStudent.value}));
    // }
  };

  const formatFeeTypes = (payments : any[], tfPayments : any, cfPayments : any, status) => {
    const seen = new Set()
    let newPayments = payments && payments.length && payments.reduce((unique, item) => {
        const key = item?.studentFeeId?.feePlanFeeTypeId?.feeTypeId?._id;
        const val = item?.studentFeeId?.feePlanFeeTypeId?.feeTypeId?.name;
        if (!seen.has(key)) {
          seen.add(key);
          unique.push(val);
        }
        return unique;
      }, []);
    if(!newPayments){
        newPayments = [];
    }
    if(tfPayments?.length){
        newPayments.push("Transport Fee");
    }
    if(cfPayments?.length){
        const newCfPayments = cfPayments.reduce((unique, cfItem) => {
            const key = cfItem?.customFeeId?.feeTypeId;
            const val = cfItem?.customFeeId?.feeType?.name;
            if (!seen.has(key)) {
              seen.add(key);
              unique.push(val);
            } 
            return unique;
        }, [])
        if(newCfPayments?.length){
            newPayments = [...newPayments, ...newCfPayments];
        }
    }
    const data = <div className="d-flex flex-row flex-wrap">{
        newPayments && newPayments.length && newPayments.map((item, idx) => {
            return <span className={`me-1 ${status === "CANCELLED" ? "theme-danger-color" : ""}`} style={newPayments?.length >= 2 ? {lineHeight: "23px"} : {}}>{item} {((newPayments?.length - 1) === idx ? "" : newPayments?.length >= 2 && ", ")}</span>
        })       
    }</div>
    return data;
}

  const setSiblingsInMultiSelect = async () => {
    const selectedSiblings = profileData?.userId && profileData?.userId?.siblings?.length && profileData?.userId?.siblings?.map((item: any) => {
      const foundSibling = options?.find((ele) => ele.value === item)
      if (foundSibling) {
        return foundSibling;
      } else {
        return null;
      }
  })
    const finalSelectedValues = selectedSiblings.filter((item) => !!item);
      setSelectedValues(finalSelectedValues);
    }
      
    useEffect(()=>{
      if(branchId || selectedBranchId){
        getEnrolledStudentsHelper({branchId: branchId || selectedBranchId, branchAcademicBatchId: branchAcademicBatchId || selectedYear})
      }
  },[branchId, branchAcademicBatchId, selectedBranchId, selectedYear])

  useEffect(()=>{
    if(profileData && options){
      setSiblingsInMultiSelect()
    }
  },[profileData, options])


  
  useEffect(() => {
    if (admittedStudents?.data) {
      const modified = admittedStudents?.data?.filter((item) => item?.user?._id !== profileData?.userId?._id).map((item) => ({
        label: item?.user?.firstName + " " + (item?.user?.middleName ? item?.user?.middleName + " " : "") + " " + (item?.user?.lastName ? item?.user?.lastName + " " : ""),
        value: item?.user?._id,
      }));
      setOptions(modified);
    }
  }, [admittedStudents?.data])


  // React.useEffect(() => {
  //   if (!usersLoading) {
  //     let array = [];
  //     if (searchusersdetails?.length) {
  //       for (let data of searchusersdetails) {
  //         array.push({
  //           label:
  //             data?.user?.firstName +
  //             " " +
  //             (data?.user?.middleName ? data?.user?.middleName : "") +
  //             " " +
  //             (data?.user?.lastName ? data?.user?.lastName : ""),
  //           // " - " +
  //           // "\n" ,
  //           // data?.user?.fatherMobile + "\n" + 
  //           // data?.user?.enrollmentCode,
  //           value: data._id,
  //           data: data,
  //         });
  //       }
  //     }
  //     setOptions([...array]);
  //   }
  // }, [searchusersdetails]);

  React.useEffect(() => {
    // if (search) {
    //   dispatch(getStudent({ id: searchedStudent.value }));
    //   dispatch(getStudentfees({ studentBranchAcademicYearId: searchedStudent.value }));
    // } else {
      dispatch(getStudent({ id: selectedStudent }));
      dispatch(getStudentfees({ studentBranchAcademicYearId: selectedStudent }));
    // }
  }, [dispatch]);

  React.useEffect(() => {
    setprofileData(student);
    studentInfoReset(student?.userId as any);
    studentInfoReset({ dateOfBirth: new Date((student?.userId as any)?.dateOfBirth)?.toLocaleDateString("en-CA") })
    if ((student?.userId as any)?.image_path) {
      setImgPath((student?.userId as any)?.image_path);
      getProfileImage((student?.userId as any)?.image_path);
    } else {
      setImgPath("");
      setImgSrc("/images/profile-img-placeholder.svg");
    }
  }, [student]);

  React.useEffect(() => {
    if (!studentfeeLoading) {
      if (studentfee?.fees?.length > 0) {
        const feeplans = [];
        const feeplansData: any[] = [];
        studentfee?.fees &&
          studentfee.fees.map((item) => {
            const idx = feeplans.indexOf(item?.feePlanFeeTypeId?.feePlanId._id);
            if (idx > -1) {
              feeplansData[idx].feeTypes.push(item?.feePlanFeeTypeId);
            } else {
              feeplans.push(item?.feePlanFeeTypeId?.feePlanId._id);
              feeplansData.push({
                feeplanName: item?.feePlanFeeTypeId?.feePlanId?.name,
                feeTypes: [item?.feePlanFeeTypeId],
              });
            }
          });
        setFeePlanData(feeplansData);
      } else {
        setFeePlanData([]);
      }
      setStudentFeeHistory(studentfeetransactions);
    }
  }, [studentfeetransactions]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

  }, [logo]);

  async function getProfileImage(id: any) {
    const data: any = await dispatch(getlogo(id));
    setImgSrc(data);
  }

  // const loadOptions = (
  //   inputValue: string,
  //   setSearchLoading?: any
  // ) => {
  //   dispatch(
  //     searchStudents({
  //       searchString: (inputValue.length > 2 ? inputValue : ""),
  //       branchAcademicBatchId: headerstate.branchAcademicBatchId,
  //       branchId: branchId,
  //       // academicClassId: AcademicClassId !== "undefined" ? AcademicClassId : "",
  //       // academicSectionId: sectionId !== "undefined" ? sectionId : "",
  //       skip: 0,
  //       limit: 5,
  //     }, setSearchLoading)
  //   );
  // }

  // const getValue = (e: any) => {
  //   dispatch(getStudent({ id: e.value }));
  //   dispatch(StudentFeetransactions({ studentBranchAcademicYearId: e.value }))
  //   setSearch(true);
  //   setSearchedStudent(e);
  // };
  const changeImageHandler = async (e: any) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", "user");
    formData.append("uniqueId", s3_uniqId);
    const {data : fileUploadedData} = await uploadAttachment(formData, "", {});
    if(fileUploadedData?.key){
      dispatch(setAlert({
        alertType : "success",
        status : 200,
        msg : "Successfully Uploaded File"
      }))
      setImgPath(fileUploadedData.key);
      getProfileImage(fileUploadedData.key);
    } else {
      dispatch(setAlert({
        alertType : "danger",
        status : 200,
        msg : "Failed to Upload File"
      }))
      setImgPath("");
      setImgSrc("/images/profile-img-placeholder.svg");
    }
  };

  const printAdmissionForm = async () => {
    let payload = {
      branchId : branchId || selectedBranchId,
      enrolledStudentId: profileData._id,
    }
    await dispatch(printingAdmissionForm(payload, dispatch, setIsPrintAdmissionLoader));
  }

  const downloadAdmissionForm = async () => {
    let payload = {
      branchId : branchId || selectedBranchId,
      enrolledStudentId: profileData._id,
    }
    await dispatch(downloadingAdmissionForm(payload, dispatch, setIsDownloadLoader));
  };

  const editChangesHandler = (values: StudentInfoInitialValues) => {
    if (phoneError || fatherPhoneError || motherPhoneError || emailError){
      dispatch(
        setAlert({
          msg: phoneError || fatherPhoneError || motherPhoneError || emailError,
          status: 400,
          alertType: "danger",
        })
      );
      return;
    }
    
    const studentInfoData = {
      ...values,
      gender: values.gender[0],
      dateOfBirth: new Date(values.dateOfBirth),
      image_path: imgPath ? imgPath : "",
    };


    setConfirmationBoxData({
      show: true,
      data: { ref: "studentInfo", studentInfoData },
    });
  };

  const printReceipt = async (item: any) => {
    let payload = {
      "receiptId": item?._id,
      "userId": curUser,
    }
    setIsPrintLoader(payload.receiptId);
    await dispatch(printingReceipt(payload, dispatch));
    setIsPrintLoader("");
  }

  const updateStudentData = async () => {
    setEditingMode(false);
    await dispatch(
      updateStudent(
        confirmationBoxData.data.studentInfoData,
        profileData.userId._id,
        ""
      )
    );
    dispatch(getStudent({ id: profileData._id }));
  };

  const deleteStudentProfilePicture = async () => {
    setEditingMode(false);
    await dispatch(deleteLogo(imgPath));
    await dispatch(
      updateStudent(
        {
          ...confirmationBoxData.data.studentInfoData,
          image_path: "",
        },
        profileData.userId._id,
        ""
      )
    );
    await dispatch(getStudent({ id: profileData._id }));
    setImgSrc("/images/profile-img-placeholder.svg");
  }

  const handleBack = () => {
     navigate("/modules/student_info/student_details", {state : {status : defaultStatus, pagination: pagination, filterPayload: filterPayload }});
    // setSearch(false);
  }

  const handleMove = () => {
    // if (!search) {
      navigate("/modules/finance/pay_fee/", { state: { id: selectedStudent, userId : profileData?.userId?._id } }
      )
    // }
    // else {
    //   navigate("/modules/finance/pay_fee/", { state: { id: searchedStudent.value } })
    // }
  }

  
  const handleShow = async() => {
    setShow(true);
    await dispatch(getStudentJourney({ branchId: branchId || selectedBranchId , studentId : profileData?.userId?._id}));
  }

  const handleClose = () => {
    setShow(false);
  }

  const data = [
    {
      title: "Total Present Days",
      percentage: 83,
      text: "239 Total Present days",
      pathColor: "#1D2D63",
    },
    {
      title: "Total Absent",
      percentage: 50,
      text: "50 absent in school",
      pathColor: "#3B5DC7",
    },
    {
      title: "Absent",
      percentage: 12,
      text: "6 absents",
      pathColor: "#DC1C1C",
    },
    {
      title: "Absent On Leave",
      percentage: 38,
      text: "44 leaves",
      pathColor: "#10A83B",
    },
  ];

  const sampleData = [
    {
      subjectName: "Mathematics",
      exams: [
        { examType: "Unit Test-1", maxMarks: "30", marksObtained: "30", grades: "A+" },
        { examType: "Unit Test-2", maxMarks: "30", marksObtained: "30", grades: "A+" },
        { examType: "Unit Test-3", maxMarks: "30", marksObtained: "30", grades: "A+" }
      ]
    },
    {
      subjectName: "Hindi",
      exams: [
        { examType: "Final Exams", maxMarks: "100", marksObtained: "88", grades: "A+" },
        { examType: "Mid Term Exam", maxMarks: "70", marksObtained: "60", grades: "A+" }
      ]
    }
  ]


  const actionImages = {
    "TRANSFER": "/images/student-profile-journey-admission-withdrawal.svg",
    "TRANSFER_UNDO": "/images/student-profile-journey-admission-withdrawal-undo.svg",
    "DEMOTION": "/images/student-profile-journey-demotion.svg",
    "DEMOTION_UNDO": "/images/student-profile-journey-demotion-undo.svg",
    "PROMOTION": "/images/student-profile-journey-promotion.svg",
    "PROMOTION_UNDO": "/images/student-profile-journey-promotion-undo.svg",
    "FINANCIAL_STUDENT_PROMOTION": "/images/student-profile-journey-promotion.svg",
    "FINANCIAL_STUDENT_PROMOTION_UNDO": "/images/student-profile-journey-promotion-undo.svg",
    "ADMISSION": "/images/student-profile-journey-admission-confirmed.svg",
  };
  const [studentInsta, setInstalments] = React.useState<any>();

  const [pendingFees, setPendingFees] = useState([])
  const [transactionHistory, setTransactionHistory] = useState([])
  const [viewPending, setViewPending] = useState("")
  const [viewTransactions, setViewTransactions] = useState("")
  const _showIncome = (selectedList: any) => {
    let newArr = [...studentInsta];
    for (let data of newArr) {
      if (data?._id === selectedList?._id) data.check = !data.check;
    }
    setInstalments(newArr);
  };

  const _viewPending = (item) => {
    if (item === ""){
      setViewTransactions("")
      setViewPending("Pending")
      setPendingFees(studentInsta)

    }
    else {
      setViewPending("")
      setPendingFees([])
    }
  };

  const _viewTransactions = (item) => {
    if (item === ""){
      setViewPending("")
      setViewTransactions("Transactions")
      setTransactionHistory(studentFeeHistory)
    }
    else {
      setViewTransactions("")
      setTransactionHistory([])
    }
  };

  useEffect(() => {
    if (!studentfeeLoading) {
        
        let array = []
        if (studentfee?.fees?.length) {
            for (let data of studentfee.fees) {
              data["totaldue"] = data.due
              data["feetypename"] = data?.feePlanFeeTypeId?.feeTypeId?.name
              data["installments"] = data?.feePlanFeeTypeId?.instalments
              if (data?.feePlanFeeTypeId?.instalments && data?.feePlanFeeTypeId?.instalments.length){
                data["dueDate"] = moment(data?.feePlanFeeTypeId?.instalments[0]?.dueDate).format("DD-MM-YYYY");
                data.installments.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
              }
              array.push(data)
            }
          }
        if(studentfee.customFees?.length){
          for (let data of studentfee.customFees) {
            data["totaldue"] = data.due
            data["feetypename"] = data?.feeType?.name
            data["installments"] = data?.instalments
            if (data?.instalments && data?.instalments.length){
              data["dueDate"] = moment(data?.instalments[0]?.dueDate).format("DD-MM-YYYY");
              data.installments.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
            }
            array.push(data)
          }
        }
        if (studentfee?.transportFee) {
      
          let data = studentfee.transportFee;
      
          data["feetypename"] = "Transport Fee";
          data["totaldue"] = data?.due;
          data["installments"] = data?.instalments;
      
          if (data?.instalments && data?.instalments?.length) {
              data["dueDate"] = moment(data.instalments[0]?.dueDate).format("DD-MM-YYYY");
              data.installments.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
          }
          array.push(data);
      }

      setInstalments(array)

        if(!studentfee?.fees?.length && !studentfee?.transportFee?.length && !studentfee?.customFees?.length) {
            setInstalments([]);
        }
    }

}, [studentfee]);


  const loadMainModules = (feeplan: any, key: any) => {
    return (
      <Accordion key={key}>
        <Accordion.Header key={key}>{feeplan.feeplanName}</Accordion.Header>
        <Accordion.Body className="w-100">
          {feeplan?.feeTypes?.length ? (
            <Col md={12}>
              {feeplan.feeTypes.map((feeType: any, key2: any) => {
                if (feeType?.instalments?.length < 1) {
                  return;
                } else {
                  return (
                    <Accordion>
                      <Accordion.Header key={key}>
                        {feeType?.feeTypeId?.name}
                      </Accordion.Header>
                      <Accordion.Body className="w-100">
                        <div className="table-fixed-height"></div>
                        <Table responsive hover className="theme-content-table w-100">
                          <thead>
                            <tr key={key2}>
                              <th>S.No</th>
                              <th>Instalment</th>
                              <th>Due</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {feeType?.instalments?.map(
                              (item, idx) =>
                                item.due > 0 && (
                                  <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item?.due?.toFixed(2)}</td>
                                    <td>
                                      {dateFormatter(item?.dueDate)}
                                    </td>
                                    <td>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={handleMove}
                                        className="theme-primary-color"
                                      >
                                        <u>Pay Now</u>
                                      </a>
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion>
                  );
                }
              })}
            </Col>
          ) : (
            "No fee due found"
          )}
        </Accordion.Body>
      </Accordion>
    );
  };

  const fullName =
    profileData?.userId?.firstName +
    " " +
    (profileData?.userId?.middleName ? profileData?.userId?.middleName : "") +
    " " +
    (profileData?.userId?.lastName ? profileData?.userId?.lastName : "");

  const studentAddressData =
    (profileData?.userId?.currentAddress?.houseNumber
      ? "H.No: " + profileData?.userId?.currentAddress?.houseNumber + ","
      : "") +
    " " +
    (profileData?.userId?.currentAddress?.addressLine
      ? profileData?.userId?.currentAddress?.addressLine + ","
      : "") +
    " " +
    (profileData?.userId?.currentAddress?.city
      ? " City: " + profileData?.userId?.currentAddress?.city + ","
      : "") +
    " " +
    (profileData?.userId?.currentAddress?.state
      ? " State: " + profileData?.userId?.currentAddress?.state + ","
      : "") +
    " " +
    (profileData?.userId?.currentAddress?.pinCode
      ? " Pin: " + profileData?.userId?.currentAddress?.pinCode
      : "");

   function calculateAge(dob) {
        var diff_ms = Date.now() - new Date(dob).getTime();
        var age_dt = new Date(diff_ms);
      
        var years = Math.abs(age_dt.getUTCFullYear() - 1970);
        var months = age_dt.getUTCMonth();
      
        return { years, months };
    }

   let age = calculateAge(profileData?.userId?.dateOfBirth)

  return (
    <div className="position-relative" style={{minHeight:"80%"}}>
      <BackButton handleBack={handleBack}/>
      <div className="theme-content-container" >
        <div className="theme-content-heading">
          <h6>Student Profile</h6>
          <ToolTip info="student info" />
        </div>
        <div className="theme-content-filters">
          {/* <div className="me-auto">
            <SearchSelect
              controlWidth="174px"
              placeholder="Search Students"
              selectedValue={null}
              dataArray={options}
              onSelectOption={getValue}
              loadOptions={loadOptions}
              isSearchable={true}
              isMulti={false}
              stylesType="regular"
            />
          </div> */}
          <button className="theme-modal-btn theme-external-action-btn d-flex me-3 align-items-center" onClick={handleShow}>
            <img className="me-2" src="/images/student-profile-journey-icon.svg" />
            Student Journey
          </button> 
         </div>
          
         <Modal
          className="theme-modal-md"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Student Journey</Modal.Title>
          </Modal.Header>

          <Modal.Body className="px-0">
          {studentJourneyLoading ? <ButtonLoader color="#2A5BC7" /> : studentJourney && studentJourney.length 
          ? studentJourney.map((data:any, index:number) => {
            return (
              <div key={index}>
                <span className="ms-2 fw-bold">{data?.branchAcademicBatchId}</span>
                {data?.items && data?.items.length && data?.items.map((item:any, innerIndex:number) => (
                  <div key={innerIndex} className={innerIndex === data.items.length - 1 ? "mb-0 mt-1" : "mb-3 mt-1"}>
                    <div className="d-flex gap-2 mb-2" style={{ height: "55px" }}>
                      <div className="ms-3">
                        <img src={actionImages[item?.type] || "/images/student-profile-journey-promotion.svg"}style={{borderRadius : "100vh"}} width={31.99} height={31.99} alt="" />
                        {innerIndex !== data.items.length - 1 ? (
                          <div style={{
                            position: "relative",
                            height: "3.5rem",
                            width: "1px",
                            backgroundColor: "#DDDDDD",
                            display: "block",
                            top: "0.5rem",
                            left: "1.1rem"
                          }}></div>
                        ) : <></>}
                      </div>
                      <div>
                        <p className="f-14 fw-bold mb-1">{item?.title}</p>
                        <p className="f-12 mb-0 me-4">{RemoveHtmlTags(item?.description)}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div></div>
                      <div className="me-4" style={{ color: "#73809D" }}>
                        {dateFormatter(item?.date)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })
          
          : <img
          src="/images/empty-data.svg"
          className="img img-fluid "
          style={{
            height: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
        />
          }
          </Modal.Body>
        </Modal> 

      <Form className={"main-profile-content me-0 d-flex flex-column flex-1"} style={{minHeight:"80%"}}>
        <Row className={"d-flex"} style={{minHeight:"70vh"}}>
          <Col
            md={3} className="personal-profile-aside text-center border-right-gray px-0"
            style={{minHeight:"70vh"}}
          > 
            <div className="pro-img align-self-md-start align-self-center rounded-circle">
              {editingMode ? (
                <>
                <label>
                  <img
                    src={imgSrc}
                    alt="user"
                    className="editingImage rounded-circle"
                  />

                  <div>
                    <img className="edit" src="./images/profile-img-edit.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      className="d-none"
                      onChange={changeImageHandler}
                      />
                  </div>

                </label>
                  <div>
                    <img className="delete" src="./images/profile-img-delete.svg" onClick={(event) => {
                      event.stopPropagation();
                      setConfirmationBoxDelete({
                        show: true,
                        data: { ref: "deleteStudentProfilePic", imgSrc },
                      });
                    }}
                    />
                    
                  </div>
                </>
              ) : (
                <img
                  src={imgSrc}
                  alt="user"
                  className="rounded-circle"
                />
              )}
            </div>
            <div className="mt-3">
              <h6 className="mb-6 theme-dark-color fs-heading">{fullName}</h6>
              <small className="theme-secondary-color fs-small">
                Age : {age?.years} y {age?.months} months 
              </small>
              <section className="w-75 mx-auto">
                <h5 className="mt-5 mb-3 fs-small theme-secondary-color d-flex">
                  <span className="w-50 ms-4">
                    Class :
                  </span>
                  <span className="theme-dark-color fs-small ms-4 d-flex flex-wrap">
                    {profileData?.academicClassId?.branchClassId?.name || ""}
                  </span>
                </h5>
                <h5 className="mb-3 fs-small theme-secondary-color d-flex">
                  <span className="w-50 ms-4">
                    Section :
                  </span>
                  <span className="theme-dark-color fs-small ms-4 d-flex flex-wrap">
                    {profileData?.academicSectionId?.sectionId?.sectionName || ""}
                  </span>
                </h5>

                {profileData?.userId?.dateOfAdmission && <h5 className="mb-3 fs-small theme-secondary-color d-flex">
                  <span className="w-50 ms-4">Admission Date :</span>
                  <span className="theme-dark-color fs-small ms-4 d-flex flex-wrap">
                    {dateFormatter(profileData?.userId?.dateOfAdmission)}
                  </span>
                </h5>
                }
                <h5 className="mb-3 fs-small theme-secondary-color d-flex">
                  <span className="w-50 ms-4">
                    Transport : <span className="text-secondary"></span>{" "}
                  </span>
                  <span className="theme-dark-color fs-small ms-4 d-flex flex-wrap">
                    {profileData?.userId?.isTransportNeeded ? "Yes" : "No"}
                  </span>
                </h5>
                <h5 className="mb-3 fs-small theme-secondary-color d-flex">
                  <span className="w-50 ms-4">
                    Address : <span className="text-secondary"></span>{" "}
                  </span>
                  <span className="theme-dark-color fs-small ms-4 d-flex flex-wrap">
                    {studentAddressData}
                  </span>
                </h5>
                <h5 className="mb-3 mt-5 fs-small theme-secondary-color d-flex justify-content-center align-items-center">
                  <button type="button" className="theme-modal-btn theme-external-action-btn ms-16 d-flex align-items-center justify-content-center gap-2" onClick={() => printAdmissionForm()}>{isPrinterAdmissionLoader ? <ButtonLoader color={"#121212"} /> : <><img src="/images/print-btn.svg" /> Print Admission Form</>}</button>
                </h5>
                <h5 className="mb-3 fs-small theme-secondary-color d-flex justify-content-center align-items-center">
                  <button type="button" className="theme-modal-btn theme-download-btn ms-16 d-flex align-items-center justify-content-center gap-2" onClick={() => downloadAdmissionForm()}>{isDownloadLoader ? <ButtonLoader color={"#121212"} /> : <><img src="/images/download-btn.svg" /> Download Admission Form</>}</button>
                </h5>
              </section>
              {/* <h5 className="mb-3 fs-small theme-secondary-color d-flex flex-wrap">
                <span className="w-50 ms-4">
                  Bus No : <span className="text-secondary"></span>
                </span>
                <span className="theme-dark-color fs-small ms-4">
                  {profileData?.userId?.bus || "3"}
                </span>
              </h5> */}
              {/* <h5 className="mt-3 mb-3 fs-small theme-secondary-color d-flex flex-wrap">
                <span className="w-50 ms-4">
                  Category : <span className="text-secondary"></span>{" "}
                </span>
                <span className="theme-dark-color fs-small ms-4">
                  {(profileData?.userId?.isDaysholarDay
                    ? "Day Scholar"
                    : profileData?.userId?.isTransportNeeded &&
                      "Transport Scholar") || ""}
                </span>
              </h5> */}
            </div>
            
          </Col>
          <>
            <Col md={9} className="personal-profile-content ps-0" style={{minHeight:"70vh"}}>
              <Tabs
                defaultActiveKey="Personal Information"
                onSelect={changeTabHandler}
                id="uncontrolled-tab-example"
                className="theme-tabs nav nav-tabs"
              >
                <Tab
                  eventKey="Personal Information"
                  title="Personal Information"
                  className="px-0"
                  mountOnEnter
                  unmountOnExit
                >
                  {/*<Col md={9} className="personal-profile-content p-0"> */}
                  <div className="profile-headers mt-3 ms-16 me-4">
                    <h4 className="mb-0 fs-heading">Personal Information</h4>
                    {
                      <button
                        type="button"
                        onClick={setEditingHandler}
                        className="profile-edit-btn"
                      >
                        <img src="/images/table-edit-btn.svg"></img>
                      </button>
                    }
                  </div>

                  {!editingMode ? (
                    <Row className="ps-2 pe-16 m-0 mt-2">
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              First Name
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editingMode}
                            className="theme-input-control"
                            defaultValue={profileData?.userId?.firstName}
                            {...studentInfoRegister("firstName")}

                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Middle Name
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editingMode}
                            className="theme-input-control"
                            defaultValue={profileData?.userId?.middleName}
                            {...studentInfoRegister("middleName")}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Last Name
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editingMode}
                            className="theme-input-control"
                            defaultValue={profileData?.userId?.lastName}
                            {...studentInfoRegister("lastName")}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Phone Number
                            </span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editingMode}
                            className="theme-input-control"
                            defaultValue={profileData?.userId?.phone}
                            {...studentInfoRegister("phone")}
                            />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">E-Mail</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editingMode}
                            className="theme-input-control"
                            defaultValue={profileData?.userId?.email}
                            {...studentInfoRegister("email")}
                            />
                        </Form.Group>
                      </Col>
                      <Col
                        md={4}
                        className="mb-3"
                      >
                        <Form.Label>
                          Transport
                        </Form.Label>
                        <div className="w-100 mt-1">
                          <input
                            {...studentInfoRegister("isTransportNeeded")}
                            type="radio"
                            defaultChecked={
                              !!profileData?.userId?.isTransportNeeded
                            }
                            value="true"
                            disabled={true}
                            id="inline-checkbox-1"
                            className="me-2 border"
                          />
                          <label htmlFor="inline-checkbox-1">Yes</label>
                          <input
                            {...studentInfoRegister("isTransportNeeded")}
                            type="radio"
                            defaultChecked={
                              !profileData?.userId?.isTransportNeeded
                            }
                            value="false"
                            disabled={true}
                            id={`inline-checkbox-2`}
                            className="ms-4 me-2"
                          />
                          <label htmlFor="inline-checkbox-2">No</label>
                        </div>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Father's Name
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="theme-input-control"
                            disabled={!editingMode}
                            defaultValue={profileData?.userId?.fatherName}
                            {...studentInfoRegister("fatherName")}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Father's Mobile
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editingMode}
                            className="theme-input-control"
                            defaultValue={profileData?.userId?.fatherMobile}
                            {...studentInfoRegister("fatherMobile")}
                        />
                        </Form.Group>
                      </Col>
                      {/* <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Father's Email
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="theme-input-control"
                            disabled={!editingMode}
                            defaultValue={profileData?.userId?.fatherEmail}
                          />
                        </Form.Group>
                      </Col> */}

                      <Col md={4} className="mb-3">
                        <Form.Group className="position-relative">
                          <div className="floating-textarea w-100 mb-0 mb-md-1">
                            <Form.Label>
                              <span className="theme-primary-color">
                                Address
                              </span>
                            </Form.Label>
                            <Form.Control
                              style={{ height: "103px" }}
                              disabled={!editingMode}
                              as="textarea"
                              rows={5}
                              className="theme-input-control pb-4"
                              defaultValue={profileData?.userId?.currentAddress?.state}
                              {...studentInfoRegister("currentAddress.state")}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Mother's Name
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="theme-input-control"
                            disabled={!editingMode}
                            defaultValue={profileData?.userId?.motherName}
                            {...studentInfoRegister("motherName")}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <span className="theme-primary-color">
                              Mother's Mobile
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="theme-input-control"
                            disabled={!editingMode}
                            defaultValue={profileData?.userId?.motherMobile}
                            {...studentInfoRegister("motherMobile")}
                          />
                        </Form.Group>
                      </Col>
                      <div className="border-bottom-gray mt-16"></div>
                      {/* Additional Details */}
                      <Row className="my-4 m-0">
                        <div className="mt-3 mt-lg-0 w-100 p-0">
                          <div className="mb-1">
                            <div className="profile-headers">
                              <div className="d-flex align-items-center">
                                <h5 className="mb-0">Additional Details</h5>
                              </div>
                            </div>
                            <div className="mt-2">
                              <Row className="m-0 p-0">
                                <Col md={4} className="ps-md-0">
                                  <Form.Group className="mb-3 mb-md-1">
                                    <Form.Label>
                                      <span className="theme-primary-color">
                                        Date Of Birth
                                      </span>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      type="date"
                                      className="theme-input-control"
                                      disabled={!editingMode}
                                      {...studentInfoRegister("dateOfBirth")}
                                      defaultValue={
                                        profileData?.userId?.dateOfBirth
                                          ? new Date(
                                            profileData.userId.dateOfBirth
                                          )?.toLocaleDateString("en-CA")
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={4} className="ps-md-0">
                                  <Form.Group className="mb-3 mb-md-1">
                                    <Form.Label>
                                      <span className="theme-primary-color">
                                        Gender
                                      </span>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      disabled={!editingMode}
                                      className="theme-input-control"
                                      value={
                                        profileData?.userId?.gender.toUpperCase() ===
                                          "M"
                                          ? "Male"
                                          : "Female"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group controlId={"siblings"} className="mb-3">
                                    <Form.Label>
                                      Siblings
                                    </Form.Label>
                                    <div className="disable-actions">
                                    <MultiSelect dataArray={options}
                                      isMulti={true}
                                      isSearchable={true}
                                      selectedValue={selectedValues}
                                      type="valueNeeded"
                                      placeholder={"Select Siblings"}
                                      onSelectOption={(e: any[]) => {
                                        const ids = []
                                        if (e.length) {
                                          e.forEach((item) => {
                                            ids.push(item.value)
                                          })
                                        }
                                        setSelectedValues(e);
                                        setValue("siblings", ids);
                                      }}
                                      stylesType={"regular"}
                                    />
                                    </div>
                                    
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <div className="text-end me-4 mt-4" style={{ visibility: "hidden" }}>
                        <button
                          className="theme-modal-btn theme-action-btn me-2"
                        >
                          Save Changes
                        </button>
                      </div>
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="w-100"
                        onSubmit={studentInfoHandleSubmit(editChangesHandler)}
                      >
                        <Row className="ps-2 pe-16 m-0 mt-2">
                          <Col md={4} className="mb-3">
                            <Form.Group>
                              <Form.Label>
                                First Name
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="text"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.firstName}
                                {...studentInfoRegister("firstName")}
                              />
                            </Form.Group>
                          </Col>
                          <Col
                            md={4}
                            className="mb-3"
                          >
                            <Form.Group>
                              <Form.Label>
                                Middle Name
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="text"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.middleName}
                                {...studentInfoRegister("middleName")}
                              />
                            </Form.Group>
                          </Col>
                          <Col
                            md={4}
                            className="mb-3"
                          >
                            <Form.Group>
                              <Form.Label>
                                Last Name
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="text"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.lastName}
                                {...studentInfoRegister("lastName")}
                              />
                            </Form.Group>
                          </Col>
                          <Col
                            md={4}
                            className="mb-3"
                          >
                            <Form.Group>
                              <Form.Label>
                                Phone Number
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="number"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.phone}
                                {...studentInfoRegister("phone")}
                                onChange={(e) => {
                                  if (e.target.value.length > 10) {
                                    setPhoneError('Phone number is too long');
                                  } else if (e.target.value.length > 0 && e.target.value.length < 10) {
                                    setPhoneError('Phone number is too short');
                                  } else {
                                    setPhoneError('');
                                  }
                              }}
                              />{phoneError  && <div className="invalid-feedback d-flex">{phoneError}</div>}
                            </Form.Group>
                          </Col>
                          <Col md={4} className="mb-3">
                            <Form.Group>
                              <Form.Label>
                                E-Mail
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="text"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.email}
                                {...studentInfoRegister("email")}
                                onChange={(e) => {
                                  const email = e.target.value;
                                  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                                
                                  if (e.target.value && !emailRegex.test(email)) {
                                    setEmailError('Invalid email format');
                                  } else {
                                    setEmailError('');
                                  }}
                                }
                                />
                                {emailError && <p className="invalid-feedback d-flex">{emailError}</p>}
                            </Form.Group>
                          </Col>
                          <Col
                            md={4}
                            className="mb-3"
                          >
                            <Form.Label>
                              Transport
                            </Form.Label>
                            <div className="w-100 mt-1">
                              <input
                                {...studentInfoRegister("isTransportNeeded")}
                                type="radio"
                                defaultChecked={
                                  !!profileData?.userId?.isTransportNeeded
                                }
                                value="true"
                                id="inline-checkbox-1"
                                className="me-2 border"
                              />
                              <label htmlFor="inline-checkbox-1">Yes</label>
                              <input
                                {...studentInfoRegister("isTransportNeeded")}
                                type="radio"
                                defaultChecked={
                                  !profileData?.userId?.isTransportNeeded
                                }
                                value="false"
                                id={`inline-checkbox-2`}
                                className="ms-4 me-2"
                              />
                              <label htmlFor="inline-checkbox-2">No</label>
                            </div>
                          </Col>
                          <Col md={4} className="mb-3">
                            <Form.Group>
                              <Form.Label>
                                Father's Name
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="text"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.fatherName}
                                {...studentInfoRegister("fatherName")}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4} className="mb-3">
                            <Form.Group>
                              <Form.Label>
                                Father's Mobile
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="number"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.fatherMobile}
                                {...studentInfoRegister("fatherMobile")}
                                onChange={(e) => {
                                  if (e.target.value.length > 10) {
                                    setFatherPhoneError('Phone number is too long');
                                  } else if (e.target.value.length > 0 && e.target.value.length < 10) {
                                    setFatherPhoneError('Phone number is too short');
                                  } else {
                                    setFatherPhoneError('');
                                  }
                              }}
                          
                              />{fatherPhoneError  && <div className="invalid-feedback d-flex">{fatherPhoneError}</div>}
                            </Form.Group>
                          </Col>
                          <Col md={4} className="mb-3">
                            <Form.Group className="position-relative">
                              <div className="floating-textarea w-100 mb-0 mb-md-1">
                                <Form.Label>
                                  <span className="theme-primary-color">
                                    Address
                                  </span>
                                </Form.Label>
                                <Form.Control
                                  style={{ height: "103px" }}
                                  disabled={!editingMode}
                                  as="textarea"
                                  rows={5}
                                  className="theme-input-control pb-4"
                                  defaultValue={profileData?.userId?.currentAddress?.state}
                                  {...studentInfoRegister("currentAddress.state")}

                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col
                            md={4}
                            className="mb-3"
                          >
                            <Form.Group>
                              <Form.Label>
                                Mother's Name
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="text"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.motherName}
                                {...studentInfoRegister("motherName")}
                              />
                            </Form.Group>
                          </Col>
                          <Col
                            md={4}
                            className="mb-3"
                          >
                            <Form.Group>
                              <Form.Label>
                                Mother's Mobile
                              </Form.Label>
                              <Form.Control
                                disabled={!editingMode}
                                type="text"
                                className="theme-input-control"
                                defaultValue={profileData?.userId?.motherMobile}
                                {...studentInfoRegister("motherMobile")}
                                onChange={(e) => {
                                  if (e.target.value.length > 10) {
                                    setMotherPhoneError('Phone number is too long');
                                  } else if (e.target.value.length > 0 && e.target.value.length < 10) {
                                    setMotherPhoneError('Phone number is too short');
                                  } else {
                                    setMotherPhoneError('');
                                  }
                              }}
                          
                              />{motherPhoneError  && <div className="invalid-feedback d-flex">{motherPhoneError}</div>}
                            
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="border-bottom-gray mt-16"></div>
                        <Row className="px-3 mx-1 m-0 my-4">
                          <div className="mt-3 mt-lg-0 w-100 p-0">
                            <div className="mb-1">
                              <div className="profile-headers">
                                <div className="d-flex align-items-center">
                                  <h5 className="mb-0">Additional Details</h5>
                                </div>
                              </div>
                              <div className="mt-2">

                                <Row className="m-0 p-0">
                                  <Col md={4} className="ps-md-0">
                                    <Form.Group>
                                      <Form.Label>
                                        Date Of Birth
                                      </Form.Label>
                                      <Form.Control
                                        disabled={!editingMode}
                                        type="date"
                                        className="theme-input-control h-100"
                                        {...studentInfoRegister("dateOfBirth")}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={4} className="ps-md-0">
                                    <Form.Group>
                                      <Form.Label>
                                        Gender
                                      </Form.Label>
                                      <Form.Select
                                        disabled={!editingMode}
                                        className="theme-input-control"
                                        defaultValue={profileData?.userId?.gender}
                                        {...studentInfoRegister("gender")}
                                      >
                                        <option value={""}>Select Gender</option>
                                        <option value={"M"}>Male</option>
                                        <option value={"F"}>Female</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={4}>
                                  <Form.Group controlId={"siblings"} className="mb-3">
                                    <Form.Label>
                                      Siblings
                                    </Form.Label>
                                    <MultiSelect dataArray={options}
                                      isMulti={true}
                                      isSearchable={true}
                                      selectedValue={selectedValues}
                                      type="valueNeeded"
                                      placeholder={"Select Siblings"}
                                      onSelectOption={(e: any[]) => {
                                        const ids = []
                                        if (e.length) {
                                          e.forEach((item) => {
                                            ids.push(item.value)
                                          })
                                        }
                                        setSelectedValues(e);
                                        setValue("siblings", ids);
                                      }}
                                      stylesType={"regular"}
                                    />
                                  </Form.Group>
                                </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <div className="text-end me-4 mt-4">
                          <button
                            className="theme-modal-btn theme-action-btn me-2"
                            type="submit"
                          >
                            Save Changes
                          </button>
                        </div>
                      </Form>
                      {(editingMode ||
                        confirmationBoxData.data.ref === "studentInfo") && (
                          <Confirmation
                            cancelHandler={setConfirmationBoxData}
                            info={
                              "Are you sure you want to update the student information?"
                            }
                            actionHandler={() => {
                              updateStudentData();
                              setConfirmationBoxData({
                                ...confirmationBoxData,
                                show: false,
                              });
                            }}
                            showData={confirmationBoxData}
                          />
                        )}
                        {(editingMode ||
                        confirmationBoxDelete.data.ref === "deleteStudentProfilePic") && (
                          <Confirmation
                            cancelHandler={setConfirmationBoxDelete}
                            info={
                              "Are you sure you want to delete the student Profile Picture?"
                            }
                            actionHandler={() => {
                              deleteStudentProfilePicture();
                              setConfirmationBoxDelete({
                                ...confirmationBoxData,
                                show: false,
                              });
                            }}
                            showData={confirmationBoxDelete}
                          />
                        )}
                    </>
                  )}
                </Tab>
                <Tab eventKey="Fee Details" title="Fee Details">
                  <div className="profile-headers mt-4 ms-16 me-4 cursor-pointer" onClick={() => _viewPending(viewPending)}>
                    <div className="profile-heading">
                      <span>Pending Fees</span>
                    </div>

                    <div className="profile-heading" style={{transform : viewPending === "Pending" ?  "rotate(0deg)" : "rotate(-90deg)"}}>
                      
                    <span className={`mb-1 me-3 cursor-pointer`} >
                          <img src="/images/down-arrow-false.svg" style= {{width: "20px", height: "20px"}} />

                      </span>
                    </div>
                  </div>

                  <div className="border-bottom-gray mt-16"></div>

                  {viewPending ? pendingFees && pendingFees.length ? 
                  <div className="student-fee-table overflow-auto">
                    {/* <Accordion defaultActiveKey="1" flush>
                      <Accordion.Header key={"key"}>Fee Plan</Accordion.Header>
                      <Accordion.Body className="w-100">
                        <Col md={12}>
                          {feePlanData?.length ? (
                            <Col md={12}>
                              {feePlanData.map((feeplan: any, key: any) => {
                                return loadMainModules(feeplan, key);
                              })}
                            </Col>
                          ) : (
                            "No Fee assigned to the student"
                          )}
                        </Col>
                      </Accordion.Body>
                    </Accordion> */}

                    
                    <Table responsive hover className="theme-content-table">
                       <thead>
                          <tr>
                            <th>S No.</th>
                            <th style={{textAlign:"center"}}>Fee Type</th>
                            <th style={{textAlign:"center"}}>Due Date</th>
                            <th style={{textAlign:"center"}}>Amount</th>
                            <th>
                              <p className="d-flex mb-0" style={{justifyContent:"center"}}>Action</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {pendingFees.map((feeType, feeIndex) => (
                          <>
                              <tr key={feeIndex}>
                                <td>{feeIndex+1}</td>
                                <td style={{textAlign:"center"}}>{feeType?.feetypename}</td> {/* Assuming there's a property called 'type' in feeType */}
                                <td style={{textAlign:"center"}}>{feeType?.dueDate}</td> {/* Assuming there's a property called 'dueDate' in feeType */}
                                <td style={{textAlign:"center"}}><CurrencySymbols />{" "}
                                          {new Intl.NumberFormat().format(feeType?.due)}</td> {/* Assuming there's a property called 'amount' in feeType */}
                                <td><div className=" d-flex align-items-center gap-2 cursor-pointer" onClick={() => _showIncome(feeType)} style={{justifyContent:"center"}}>
                                {" "}
                              <button
                                onClick={handleMove}
                                className="theme-tabular-action-btn cursor-pointer"
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src="/images/rupee.svg"
                                    style={{ width: "11px", height: "11px" }}
                                  />
                                  Pay Fee
                                </div>
                              </button>
                            <span className={`mb-1 me-3 ${
                                feeType.check ? "rotate-img-180" : "rotate-img-0"
                              }`}>
                                <img src="/images/down-arrow-false.svg" style={{width: "20px", height: "20px"}}/>
                                </span></div>
                          </td>
                              </tr>

                       
                              {feeType?.installments && feeType?.installments.length
                                ? feeType?.installments.map(
                                    (item2: any, index2: any) => (
                                      <tr
                                        className="no-hover-row"
                                        key={item2 ? item2._id : ""}
                                        style={{
                                          display: feeType.check ? "" : "none",
                                          width: "100%",
                                        }}
                                      >
                                        <td style={{textAlign:"right"}}>{index2+1}</td>
                                        <td style={{textAlign:"center"}}>
                                          {
                                            item2?.name
                                          }
                                        </td>
                                        <td style={{textAlign:"center"}}>
                                          {dateFormatter(item2?.dueDate)}
                                        </td>
                                        <td style={{textAlign:"center"}}>
                                          <CurrencySymbols />{" "}
                                          {new Intl.NumberFormat().format(
                                            item2?.due
                                          )}{" "}
                                        </td>
                                        <td>
                                          <a
                                            style={{ display: "flex", cursor: "pointer", justifyContent:"center" }}
                                            onClick={handleMove}
                                            className="theme-primary-color"
                                          >
                                            <u>Pay Now</u>
                                          </a>
                                    </td>
                                      </tr>
                                    )
                                  )
                                : null}
                            
                        </>
                            ))
                          }
                      </tbody>

                      </Table>
                      
                  </div>
                  : <div className='d-flex flex-column align-items-center justify-content-center'>
                  <img src='/images/empty-screen-placeholder.svg' className='img img-fluid' style={{ height: "150px" }} />
                  <h5 className='fs-small theme-dark-color mt-16'>No Pending fees for the student.</h5>
                </div>
                : <></>}
                  {/* <Card className="student-fee-table overflow-auto"> */}
                  {/* <Table responsive hover className="search-results-content">
                        <thead>
                        <tr>
                            <th>S No.</th>
                            <th>Due Date</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th><p className="ps-3 mb-0">Action</p></th>
                        </tr>
                        </thead>
                        <tbody>
                            {[1,2,3,4].map((item, i) => 
                                (<tr>
                                    <td>{i+1}</td>
                                    <td>{new Date().toString().substring(4,16)}</td>
                                    <td>Exam Fee</td>
                                    <td>{2000 + "/-"}</td>
                                    <td><a href="#" className="text-decoration-"><u>Pay Now</u></a></td>
                                </tr>)
                            )}
                        </tbody>
                        </Table> */}
                  {/* </Card> */}
                  <div className="profile-headers cursor-pointer mt-4 ms-16 me-4" onClick={() => _viewTransactions(viewTransactions)}>
                    <div className="profile-heading ">
                      <span>Transaction History</span>
                  
                    </div>

                    <div className="profile-heading" style={{transform : viewTransactions === "Transactions" ?  "rotate(0deg)" : "rotate(-90deg)"}}>
                      
                    <span className={`mb-1 me-3 cursor-pointer`} >
                          <img src="/images/down-arrow-false.svg" style= {{width: "20px", height: "20px"}} />

                      </span>
                    </div>
                  </div>
                  
                  <div className="border-bottom-gray mt-16"></div>
                  {viewTransactions ? transactionHistory && transactionHistory.length ?
                    <>
                      <div className="table-max-height"></div>
                      <Table responsive hover className="theme-content-table">
                        <thead>
                          <tr>
                            <th>S No.</th>
                            <th>Receipt Number</th>
                            <th>Date</th>
                            <th>Payment Mode</th>
                            <th>Fee Types</th>
                            <th>Amount</th>
                            <th>
                              <p className="ps-3 mb-0">Action</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionHistory.map((item: any, i: any) => (
                            <tr>
                              <td>{item?.status === "CANCELLED" ? <span className="theme-danger-color">{i + 1}</span> : i + 1}</td>
                              <td>{item?.status === "CANCELLED" ? <span className="theme-danger-color">{item?.receiptNumber} (Canceled)</span> : item?.receiptNumber}</td>
                              <td>
                                {item?.status === "CANCELLED" ? <span className="theme-danger-color">{dateFormatter(item.payDate)}</span> : dateFormatter(item.payDate)}
                              </td>
                              <td>{item?.status === "CANCELLED" ? <span className="theme-danger-color">{item?.branchPaymentMethodId?.paymentMethodId?.masterPaymentMethodId?.name}</span> : item?.branchPaymentMethodId?.paymentMethodId?.masterPaymentMethodId?.name}</td>
                              <td className="cell-align-vertically"><div className="d-flex">{formatFeeTypes((item as any)?.payments,item?.tfPayments, item?.cfPayments, item?.status)}</div></td>
                              <td>{item?.status === "CANCELLED" ? <span className="theme-danger-color">{item?.totalAmount?.toFixed(2)}</span> : item?.totalAmount?.toFixed(2)}</td>
                              <td>
                                {isPrintLoader === item?.payments?.[0]?.paymentReceiptId || isPrintLoader === item?.cfPayments?.[0]?.paymentReceiptId ? <div className="d-flex mt-2 ms-2"> <ButtonLoader color="rgb(13, 110, 253)" /> </div> :
                                  <a href="#" className="theme-primary-color" onClick={() => printReceipt(item)}>
                                    <u>Print Receipt</u>
                                  </a>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </> :
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                      <img src='/images/empty-screen-placeholder.svg' className='img img-fluid' style={{ height: "150px" }} />
                      <h5 className='fs-small theme-dark-color mt-16'>No transactions available for the student.</h5>
                    </div>
                    :<></>}
                </Tab>

                <Tab eventKey="Attendance Record" title="Attendance Record">
                  <div className='d-flex flex-column align-items-center justify-content-center py-5 h-100'>
                    <img src='/images/under_construction.svg' className='img img-fluid mt-5 pt-4' />
                    <p className='mt-16 fs-small'>Page under construction, Data will be visible when the page is completed.</p>
                  </div>

                  {/* <div className="d-flex justify-content-between px-4 pt-4">
                    {data.map((item, index) => (
                      <div>
                        <span
                          className={item?.title === "Absent" ? "px-5" : "px-4"}
                          style={{
                            fontFamily: "Lato",
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#000",
                          }}
                        >
                          {item?.title}
                        </span>
                        <div
                          className="mt-4"
                          style={{
                            width: "140px",
                            height: "140px",
                            position: "relative",
                          }}
                        > */}
                  {/* <PercentageLoader targetValue={item?.percentage} duration={5} width={100} height={100} pathColor={item?.pathColor}/> */}
                  {/* <CircularProgressbarWithChildren
                            value={item?.percentage}
                            styles={buildStyles({
                              pathColor: `${item?.pathColor}`,
                            })}
                          >
                            <div style={{ fontSize: 20, marginTop: -45 }}>
                              <span>{item?.percentage}%</span>
                            </div>

                            <div
                              style={{
                                fontSize: 12,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                color: "#5C5C5C",
                                fontWeight: "400px",
                              }}
                            >
                              <span>{item?.text}</span>
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Row className="mt-2 ms-1">
                    <Col md={12}>
                      <span style={{ fontWeight: "500px", fontSize: "18px" }}>
                        Leave History
                      </span>
                    </Col>
                  </Row>
                  <div className="border-bottom-gray mt-1"></div>
                  <Table responsive hover className="theme-content-table w-100">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Leave Type</th>
                        <th>Leave Date</th>
                        <th>Leave Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Sick Leave</td>
                        <td>20 March 2022</td>
                        <td>Sick Leave</td>
                      </tr>
                    </tbody>
                  </Table> */}
                </Tab>
                <Tab eventKey="Academic History" title="Academic History">
                  <div className='d-flex flex-column align-items-center justify-content-center py-5 h-100'>
                    <img src='/images/under_construction.svg' className='img img-fluid mt-5 pt-4' />
                    <p className='mt-16 fs-small'>Page under construction, Data will be visible when the page is completed.</p>
                  </div>
                  {/* <div>
                    <Row className="mt-2 ms-1">
                      <Col md={12}>
                        <span style={{ fontWeight: "500px", fontSize: "18px" }}>
                          Academic History
                        </span>
                      </Col>
                    </Row>
                    <Table responsive hover className="theme-content-table w-100">
                      <thead>

                        <tr>
                          <th>Subject</th>
                          <th>Exam Type</th>
                          <th>Max. Marks</th>
                          <th>Marks Obtained</th>
                          <th>Grades</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sampleData.map((item: any, index: number) => (
                          item.exams.map((exam: any, i: number) => (
                            <tr key={`${index}-${i}`}>
                              {i === 0 ? (<td>{item.subjectName}</td>) : <td></td>}
                              <td>{exam.examType}</td>
                              <td>{exam.maxMarks}</td>
                              <td>{exam.marksObtained}</td>
                              <td>{exam.grades}</td>
                            </tr>
                          ))
                        ))}
                      </tbody>
                    </Table>
                  </div> */}
                </Tab>
              </Tabs>
            </Col>
          </>
        </Row>
      </Form>
    </div>
    </div >
  );
};

export default React.memo(StudentProfile);
