import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import '@fortawesome/fontawesome-free/css/all.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import App from 'App';
import 'theme.css';
import 'index.css';
import 'resoinsive.css';
import Store from 'redux/store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: 'c948395b-d940-4424-a5e6-79efd88c1c04',
  clientToken: 'pub51d4be3e97361a28fa6f34a62b4c3a74',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'us5.datadoghq.com',
  service: 'admin-web',
  env: process.env.REACT_APP_ENVIRONMENT || 'development',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '1.7.5',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});
datadogRum.startSessionReplayRecording();
ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <Provider store={Store}>
        <App />
      </Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode >,
  document.getElementById('root')
);
